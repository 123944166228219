.company-info {
  font-size: 18px;

  @include media(tablet) {
    font-size: 12px;
  }

  > li {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #ededed;

    @include media(tablet) {
      padding: 10px 0;
      display: block;
    }

    .subject {
      flex: 1;

      @include media(tablet) {
        margin-bottom: 5px;
        font-weight: bold;
      }
    }

    span {
      @include media(tablet) {
        display: block;
      }
    }

    .content {
      flex: 3;
    }

    h4 {
      font-size: 18px;
      font-weight: bold;

      @include media(tablet) {
        font-size: 10px;
      }
    }
  }

  .disc {
    li {
      margin-left: 30px;
      list-style-type: disc;

      @include media(tablet) {
        margin-left: 20px;
      }
    }

    + h4 {
      margin-top: 20px;

      @include media(tablet) {
        margin-top: 10px;
      }
    }
  }
}
