body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
form,
ul,
li,
ol,
dl,
dt,
dd,
p,
fieldset,
pre,
code,
legend,
blockquote,
figure {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 12px;
}

li {
  list-style: none;
}

pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

a:focus {
  outline: thin dotted;
}

strong,
b {
  font-weight: bold;
}

q {
  quotes: none;
}

[title] {
  border-bottom: 1px dotted;
}

code,
samp,
kbd {
  font-family: monospace, sans-serif;
}

mark {
  background-color: $color-yellow;
  color: $color-black;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

// Embedded content
img {
  line-height: 1;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

// Tabular data
caption {
  padding: 0;
}

th,
td {
  padding: 0;
}

// Forms
form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
