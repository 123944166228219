h2 {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 30px;

  @include media(tablet) {
    font-size: 18px;
    margin-bottom: 10px;
  }

  span {
    position: relative;
    display: inline-block;
    background: #fff;
    z-index: 1;
    padding: 10px;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #000;
    position: absolute;
    top: 50%;
  }
}
