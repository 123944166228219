.business-list {
  .item {
    display: flex;

    &:nth-child(2n+1) {
      .description {
        margin-left: auto;
        order: 1;
      }
    }

    @include media(tablet) {
      display: block;
    }
  }

  .description {
    width: 380px;
    position: relative;
    z-index: 10;

    @include media(tablet) {
      width: auto;
    }

    h3 {
      font-size: 36px;
      font-weight: normal;
      margin-bottom: 12px;

      @include media(tablet) {
        font-size: 24px;
      }
    }
    p {
      font-size: 18px;
      line-height: 1.8;

      @include media(tablet) {
        font-size: 12px;
      }
    }
  }

  .image {
    position: relative;
    width: 560px;

    img {
      position: absolute;
    }

    @include media(tablet) {
      width: auto;
    }
  }

  .links {
    margin-top: 5px;

    @include media(tablet) {
      margin-top: 10px;
    }

    li {
      & + li {
        margin-top: 5px;
      }
    }

    @include media(tablet) {
      display: flex;

      > * {
        flex: 1;
        margin: 0 5px;
        line-height: 1;

        &:first-child {
          margin-left: 0;
          margin-top: 0;
        }

        &:last-child {
          margin-right: 0;
          margin-top: 0;
        }
      }

      a {
        display: block;
        border: 1px solid $color-blue;
        border-radius: 8px;
        text-align: center;
        padding: 15px;
        font-size: 12px;

        &:hover,
        &:active {
          background: $color-blue;
          color: #fff;
        }
      }
    }
  }

  #kawara {
    margin-bottom: 110px;

    img {
      width: 503px;
      height: auto;
    }

    @include media(tablet) {
      margin-bottom: 200px;

      img {
        width: 262px;
        left: 50%;
        top: 16px;
        margin-left: -131px;
      }
    }
  }

  #curio {
    margin-bottom: 230px;

    img {
      width: 754px;
      height: auto;
      left: 100px;
      top: -10px;
    }

    @include media(tablet) {
      margin-bottom: 360px;

      img {
        width: 508px;
        left: 50%;
        margin-left: -252px;
        top: 14px;
      }
    }
  }

  #utilite {
    margin-bottom: 110px;

    img {
      width: 726px;
      height: auto;
      right: 40px;
      top: -80px;
    }

    @include media(tablet) {
      margin-bottom: 300px;

      img {
        width: 480px;
        right: auto;
        left: 50%;
        margin-left: -310px;
        top: 20px;
      }
    }
  }

  #scoo {
    img {
      width: 429px;
      height: auto;
      left: 120px;
      top: -100px;
    }

    @include media(tablet) {
      margin-bottom: 320px;

      img {
        width: 300px;
        left: 50%;
        margin-left: -150px;
        top: 20px;
      }
    }
  }
}
