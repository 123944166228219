// Size
// ------------------------------------
$site-width: 940px;

// Mediaquery
// ------------------------------------
$break-point-pc: 1020px;
$break-point-sp: 380px;

// Font
// ------------------------------------
@font-face {
  font-family: "Noto Sans Japanese";
  src: url("../font/NotoSansCJKjp-Light.woff") format('woff');
}
$font-size: 1rem;
$font-family: 'Noto Sans Japanese', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Osaka", sans-serif;
$line-height: 1.5;

// Color
// ------------------------------------
$color-black: #000;
$color-white: #fff;
$color-blue: #0066ff;
$color-yellow: #ff0;
$color-gray: #c0c0c0;

$color-text: $color-black;
$color-bg: $color-white;

$color-link: #0066ff;
$color-link-visited: #0066ff;
$color-link-hover: lighten(#0066ff, 20%);
$color-link-active: lighten(#0066ff, 20%);
$color-link-focus: lighten(#0066ff, 20%);
