header {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  width: 100%;
  padding: 18px 0;
  line-height: 1;
  z-index: 1000;

  @include media(tablet) {
    padding: 0;
    background: none;
  }

  @include media(sp) {
    background: rgba(255, 255, 255, 0.9);
  }

  .content-base {
    display: flex;
    align-items: center;

    @include media(tablet) {
      display: block;
      padding: 0;
    }
  }

  #logo {
    fill: #fff;
    height: 24px;

    @include media(sp) {
      fill: #000;
      height: 16px;
    }
  }

  .logo-block {
    @include media(tablet) {
      display: block;
      padding: 16px 20px;
      text-align: center;
      background: rgba(0, 0, 0, 0.9);
    }

    @include media(sp) {
      background: rgba(255, 255, 255, 0.9);
      padding: 13px;
      text-align: left;
    }
  }

  a {
    &:link,
    &:visited {
      color: #fff;
    }
    &:hover,
    &:active {
      color: #ccc;
    }

    @include media(tablet) {
      &:link,
      &:visited {
        color: #000;
      }
      &:hover,
      &:active {
        color: #666;
      }
    }
  }

  .nav-block {
    font-size: 12px;
    margin-left: auto;
    margin-top: -4px;

    @include media(tablet) {
      display: block;
      padding: 16px 20px;
      background: rgba(255, 255, 255, 0.9);
      margin-top: 0px;
    }

    @include media(sp) {
      padding: 0;
      background: none;
      max-height: 0;
      overflow: hidden;
      transition: all ease 0.4s;

      &.open {
        max-height: 400px;
      }
    }

    ul {
      display: flex;

      @include media(tablet) {
        justify-content: center;
      }

      @include media(sp) {
        display: block;
      }
    }

    li {
      padding: 0 15px;

      @include media(tablet) {
        padding: 0 20px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @include media(sp) {
        padding: 0;
        border-top: 1px solid #ededed;

        a {
          display: block;
          padding: 13px 30px!important;
        }
      }
    }
  }

  .menu-trigger {
    width: 14px;
    height: auto;
    position: absolute;
    top: 16px;
    right: 16px;
    transition: all ease 0.2s;

    &.open {
      transform: rotate(180deg);
    }
  }
}
