@mixin clearfix {
  zoom: 1;

  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin media($media) {
  @if $media == sp {
    @media screen and (max-width: $break-point-sp) {
      @content;
    }
  } @else if $media == tablet {
    @media screen and (max-width: $break-point-pc) {
      @content;
    }
  } @else if $media == pc {
    @media screen and (min-width: $break-point-pc) {
      @content;
    }
  }
}
