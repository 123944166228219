footer {
  background: #000;
  color: #fff;
  padding: 24px 0;
  line-height: 1;
  text-align: right;
  font-size: 12px;

  @include media(tablet) {
    text-align: center;
    padding: 16px;
  }

  @include media(sp) {
    padding: 13px;
  }
}
