html {
  background: $color-bg;
  font-size: $font-size;
  height: 100%;
}

body {
  background: $color-bg;
  color: $color-text;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
}

.pc {
  @include media(tablet) {
    display: none;
  }
}

.sp {
  display: none;

  @include media(sp) {
    display: block;
  }
}

.tablet {
  display: none;

  @include media(tablet) {
    display: block;
  }
}

.content-base {
  width: $site-width;
  margin: 0 auto;

  @include media(tablet) {
    width: auto;
    padding: 0 20px;
    overflow: hidden;
  }
}

.content-block {
  margin-bottom: 74px;

  @include media(tablet) {
    margin-bottom: 36px;
  }
}

#map {
  width: 100%;
  height: 523px;
  position: relative;
}

#access {
  position: relative;
  
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
