.kv {
  margin-bottom: 74px;

  img {
    width: 100%;
    height: auto;
  }

  @include media(tablet) {
    margin-bottom: 36px;
  }
}
