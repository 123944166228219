a {
  color: $color-link;
  text-decoration: none;
  transition: all ease 0.2s;

  &:link {
    color: $color-link;
  }

  &:visited {
    color: $color-link-visited;
  }

  &:hover {
    color: $color-link-hover;
  }

  &:active {
    color: $color-link-active;
  }

  &:focus {
    color: $color-link-focus;
  }
}
